// Mixins

// Breakpoint Min
@mixin bp($breakpoint) {
  // If the breakpoint exists in the map.
  @if map-has-key($breakpoints, $breakpoint) {
    // Get the breakpoint value.
    $breakpoint-value: map-get($breakpoints, $breakpoint);

    // Write the media query.
    @media (min-width: $breakpoint-value) {
      @content;
    }

    // If the breakpoint doesn't exist in the map.
  } @else {
    // Log a warning.
    @warn 'Invalid breakpoint: #{$breakpoint}.';
  }
}

// Breakpoint Max
@mixin bp_max($breakpoint) {
  // If the breakpoint exists in the map.
  @if map-has-key($breakpoints, $breakpoint) {
    // Get the breakpoint value.
    $breakpoint-value: map-get($breakpoints, $breakpoint);

    // Write the media query.
    @media (max-width: ($breakpoint-value - 1)) {
      @content;
    }

    // If the breakpoint doesn't exist in the map.
  } @else {
    // Log a warning.
    @warn 'Invalid breakpoint: #{$breakpoint}.';
  }
}

// Background Color with Opacity
// Example: .element { @include background-rgba(#000000, .5); }}
// Output: .element { background: rgba(0, 0, 0, .5); }
@mixin background-rgba($color, $alpha) {
  background-color: $color;
  background-color: rgba($color, $alpha);
}

// Animations
@mixin animate($duration: 1s) {
  animation-duration: $duration;
  animation-fill-mode: both;
}

// Absoute Positioning
@mixin absolute-position(
  $top: false,
  $right: false,
  $bottom: false,
  $left: false
) {
  @if $top {
    top: $top;
  }
  @if $right {
    right: $right;
  }
  @if $bottom {
    bottom: $bottom;
  }
  @if $left {
    left: $left;
  }
  position: absolute;
}

// Gradient
@mixin gradient($topcolour, $botcolour, $start: 0%, $stop: 100%) {
  background: $topcolour;
  background: -moz-linear-gradient(top, $topcolour $start, $botcolour $stop);
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop($start, $topcolour),
    color-stop($stop, $botcolour)
  );
  background: -webkit-linear-gradient(top, $topcolour $start, $botcolour $stop);
  background: -o-linear-gradient(top, $topcolour $start, $botcolour $stop);
  background: -ms-linear-gradient(top, $topcolour $start, $botcolour $stop);
  background: linear-gradient(to bottom, $topcolour $start, $botcolour $stop);
  filter: progid:DXImageTransform.Microsoft.Gradient(GradientType=0, startColorstr='#{$topcolour}', endColorstr='#{$botcolour}');
  -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr='#{$topcolour}', endColorstr='#{$botcolour}')";
}

// Box Sizing
@mixin box-sizing($box-model) {
  -webkit-box-sizing: $box-model;
  -moz-box-sizing: $box-model;
  box-sizing: $box-model;
}

// Text Size
@mixin text-size-adjust($size) {
  -webkit-text-size-adjust: $size;
  -ms-text-size-adjust: $size;
}

// Border Radius
@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  border-radius: $radius;
}

// Shadows
@mixin box-shadow($string) {
  -webkit-box-shadow: $string;
  -moz-box-shadow: $string;
  box-shadow: $string;
}
@mixin drop-shadow($x: 0, $y: 1px, $blur: 2px, $spread: 0, $alpha: 0.25) {
  -webkit-box-shadow: $x $y $blur $spread rgba(0, 0, 0, $alpha);
  -moz-box-shadow: $x $y $blur $spread rgba(0, 0, 0, $alpha);
  box-shadow: $x $y $blur $spread rgba(0, 0, 0, $alpha);
}
@mixin inner-shadow($x: 0, $y: 1px, $blur: 2px, $spread: 0, $alpha: 0.25) {
  -webkit-box-shadow: inset $x $y $blur $spread rgba(0, 0, 0, $alpha);
  -moz-box-shadow: inset $x $y $blur $spread rgba(0, 0, 0, $alpha);
  box-shadow: inset $x $y $blur $spread rgba(0, 0, 0, $alpha);
}

// Gradient
@mixin gradient($topcolour, $botcolour, $start: 0%, $stop: 100%) {
  background: $topcolour;
  background: -moz-linear-gradient(top, $topcolour $start, $botcolour $stop);
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop($start, $topcolour),
    color-stop($stop, $botcolour)
  );
  background: -webkit-linear-gradient(top, $topcolour $start, $botcolour $stop);
  background: -o-linear-gradient(top, $topcolour $start, $botcolour $stop);
  background: -ms-linear-gradient(top, $topcolour $start, $botcolour $stop);
  background: linear-gradient(to bottom, $topcolour $start, $botcolour $stop);
  filter: progid:DXImageTransform.Microsoft.Gradient(GradientType=0, startColorstr='#{$topcolour}', endColorstr='#{$botcolour}');
  -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr='#{$topcolour}', endColorstr='#{$botcolour}')";
}

// Opacity
@mixin opacity($opacity: 0.5) {
  -webkit-opacity: $opacity;
  -moz-opacity: $opacity;
  opacity: $opacity;
}

// Transition
@mixin transition($transition-string) {
  -webkit-transition: $transition-string;
  -moz-transition: $transition-string;
  -ms-transition: $transition-string;
  -o-transition: $transition-string;
  transition: $transition-string;
}

// Transform
@mixin transform($string) {
  -webkit-transform: $string;
  -moz-transform: $string;
  -ms-transform: $string;
  -o-transform: $string;
  transform: $string;
}
@mixin scale($factor) {
  -webkit-transform: scale($factor);
  -moz-transform: scale($factor);
  -ms-transform: scale($factor);
  -o-transform: scale($factor);
  transform: scale($factor);
}
@mixin rotate($deg) {
  -webkit-transform: rotate($deg);
  -moz-transform: rotate($deg);
  -ms-transform: rotate($deg);
  -o-transform: rotate($deg);
  transform: rotate($deg);
}
@mixin skew($deg, $deg2) {
  -webkit-transform: skew($deg, $deg2);
  -moz-transform: skew($deg, $deg2);
  -ms-transform: skew($deg, $deg2);
  -o-transform: skew($deg, $deg2);
  transform: skew($deg, $deg2);
}
@mixin translate($x, $y: 0) {
  -webkit-transform: translate($x, $y);
  -moz-transform: translate($x, $y);
  -ms-transform: translate($x, $y);
  -o-transform: translate($x, $y);
  transform: translate($x, $y);
}
@mixin translate3d($x, $y: 0, $z: 0) {
  -webkit-transform: translate3d($x, $y, $z);
  -moz-transform: translate3d($x, $y, $z);
  -ms-transform: translate3d($x, $y, $z);
  -o-transform: translate3d($x, $y, $z);
  transform: translate3d($x, $y, $z);
}
@mixin perspective($value: 1000) {
  -webkit-perspective: $value;
  -moz-perspective: $value;
  -ms-perspective: $value;
  perspective: $value;
}
@mixin transform-origin($x: center, $y: center) {
  -webkit-transform-origin: $x $y;
  -moz-transform-origin: $x $y;
  -ms-transform-origin: $x $y;
  -o-transform-origin: $x $y;
  transform-origin: $x $y;
}
