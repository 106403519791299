/* Footer
================================================== */
footer {
    width: 100%;
  //padding: 6em 0 0;
    background-color: $black;
    color: $primary-colour;

    h4 {
      margin: 0;

    }
}

.footer {

	&__divider {
		position: relative;
		top: -1px;
	}
	&__wrap {
		width: 100%;
		//display: flex;
		//flex-flow: row wrap;
		//align-items: flex-start;
		//text-align: center;
		//justify-content: space-between;
		margin-top: 1rem;


		.row {
	
			align-items: center;
		
		}

		& > div {
			@include bp_max(medium) {
				text-align: center;
			}
			@include bp_max(xlarge) {
				margin-bottom: 3rem;
			}
		}
		p {
			font-family: $primary-font;
		}

	/*	h3 {
			margin: 0 0 2rem;
			font-weight: normal;
			font-size: 1.2rem;
			color: $secondary-colour;
			text-transform: uppercase;
			font-weight: bold;
			font-family: $primary-font;
		}
		h4 {
			margin-bottom: 0.5rem;
			font-size: 1.2rem;
			font-weight: normal;
			font-family: $primary-font;
		} */
	}

	&__left {
		//order: 4;

    text-align: center;
		@include bp(large) {
		
			
			//order: 3;
		}
		@include bp(large) {
			//order: 1;
		}

		h3 {
			margin-bottom: 1rem;
		}

		&--sponsor {
			img {
				max-width: 130px;
			}
			font-size: 0.7rem;
		}
	}

	&__logo {
		img {
			max-width: 220px;
			height: auto;
			margin: 0 auto 2rem;
			@include bp(xlarge) {
				margin: 0 0 2rem 0;
			}


		}
	}

	&__sponsors {
		display: flex;
		flex-direction: column;
		img {
			margin: 0.5rem 0;
		}
  }
   
	&__social {

    align-self: center; 

		@include bp(large) {
			margin-top: 1rem;
			//margin-left: -1rem;
		}
		ul {
			display: flex;
			flex-flow: row nowrap;
			padding: 0;
			@include bp_max(xlarge) {
				justify-content: center;
				margin: auto;
			}
		}
		li {
      list-style-type: none;
			@include bp_max(xlarge) {
				padding: 0.5rem 1rem;
			}
		}

    a {
      * {
        fill: $primary-colour;
      }
      &:hover {
        * {
          fill: $secondary-colour;
          transition: $global-transition;
        }
      }

			}
		}
	

	&__dates,
	&__hours,
	&__hocation {
		text-align: center;
		p {
			margin-bottom: 1.5rem;
		}
	}

	&__dates {
		order: 1;
		@include bp(large) {
			order: 2;
		}
	}

	&__hours {
		order: 2;
		@include bp(large) {
			order: 3;
		}
	}

	&__location {
		order: 3;
		@include bp(medium) {
			order: 4;
		}
	}

	&__bottom {
		width: 100%;
		margin: 6rem 0 0;
		padding: 1em 3em;
		background-color: $black;

		* {
			text-transform: uppercase;
		}

		.row {
			//justify-content: space-between;
			align-items: center;
			//margin: 0;
		}

		.footer__links,
		.footer__signature {
			font-size: 0.7em;
			@include bp_max(medium) {
				padding: 0.5rem 0;
				text-align: center;
			}
		}

		.footer__links {
			@include bp(medium) {
				justify-content: flex-start;
			}
			a {
				margin-right: 1rem;

				&:hover {
					color: $secondary-colour;
				}
			}
		}

		.footer__signature {
			display: flex;
			align-items: center;
			justify-content: center;
			@include bp(medium) {
				justify-content: flex-end;
			}
			img {
				margin-left: 1rem;
				width: 10em;
				@include transition($global-transition);
			}
		}
	}
}



// SVG divider 

.custom-shape-divider-footer {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  //transform: rotate(180deg);
}

.custom-shape-divider-footer svg {
  position: relative;
  display: block;
  width: calc(165% + 1.3px);

  @include bp(medium) {
	height: 143px;
}
}

.custom-shape-divider-footer .shape-fill {
  fill: #FFFFFF;
}