.table-wrap {
  width: 100%;
  overflow-x: auto;
}

table {
  text-align: left;
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  margin: 0;
  border: 1px solid $white;
  td,
  th {
    padding: 1rem 1.5rem;
    border-bottom: 1px solid $white;
    border-right: 1px solid $white;
    * {
      margin: 0;
    }
  }
  th {
    background-color: $secondary-colour;
    font-size: 1.1rem;
    * {
      color: $white;
    }
  }
}

table tr:nth-child(2n) {
  background-color: $off-white;
}
