/* Banner / Slider
================================================== */

#banner {
    //justify-items: center;
    background: url("images/yellow-waves-background.png") !important;;
    background-repeat: no-repeat !important;
    background-size: cover !important;
	//position: relative;
	//overflow: visible;
	margin: 0;
    //height: 300px;
    padding: 5rem 0 0 0;

   
    p {
        font-size: 1.6rem;
        font-weight: bold;
    }


}


// Divider after banner

.custom-shape-divider-banner {
    position: relative;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    transform: rotate(180deg);
}

.custom-shape-divider-banner svg {
    position: relative;
    display: block;
    width: calc(165% + 1.3px);
    

    @include bp(medium) {
        height: 143px;
    }
}

.custom-shape-divider-banner .shape-fill-banner {
    fill: $light-yellow;
}