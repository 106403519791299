// Images
img {
  max-width: 100%;
  height: auto;
}

body figure[data-wf-figure] {
  display: block;
}

// Video
.embed-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
