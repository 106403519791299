// No Selection
.no-select {
  user-select: none;
  cursor: default;
}

// Font Weights
.font-100 {
  font-weight: 100;
}
.font-200 {
  font-weight: 200;
}
.font-300 {
  font-weight: 300;
}
.font-400 {
  font-weight: 400;
}
.font-500 {
  font-weight: 500;
}
.font-600 {
  font-weight: 600;
}
.font-700 {
  font-weight: 700;
}
.font-800 {
  font-weight: 800;
}
.font-900 {
  font-weight: 900;
}

// Text Modifications
.uppercase {
  text-transform: uppercase;
}
.lowercase {
  text-transform: lowercase;
}
.capitalize {
  text-transform: capitalize;
}

// Text Alignments
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.text-center {
  text-align: center;
}
