/* Main
================================================== */
main {
   background-color: $light-yellow;
   //padding: 2rem 0;

   
   }


#new main {
    height: auto !important;
}

#newsletter main {
    @include bp_max(mlarge) {
        height: auto !important;
    }
}

#enquire-to-exhibit main {
    @include bp_max(mlarge) {
        height: auto !important;
    }
}

html {
 
}

.label-warning {
    display: none;
}



// Aimated word banner 

.accounting-events {

    h1 div {
        

        @include bp(medium) {
            font-size: 8.5rem;
        }
      }

   }


.words {
line-height: 0.8;
  opacity: 1;
  animation: replacement 2.5s infinite ease-in-out;

 
}

@keyframes replacement {
  0%, 100% {
      opacity: 0;
  }
  25%, 75% {
      opacity: 1;
  }
}


// Divider after main content 

.custom-shape-divider-bottom-1696251135 {
    position: relative;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    transform: rotate(180deg);
}

.custom-shape-divider-bottom-1696251135 svg {
    position: relative;
    display: block;
    width: calc(165% + 1.3px);
    
    @include bp(medium) {
        height: 143px;
    }
}

.custom-shape-divider-bottom-1696251135 .shape-fill {
    fill: #FFFFFF;
}