/* Page Specific Styling
================================================== */


// Temp landing page 

.landing-page {

display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
background-color: #ffd642;
background-image: url("images/yellow-waves-background.png") !important; 
background-size: cover;
background-repeat: no-repeat;
height: 100vh;

body {
    overflow-y:hidden;

}

}

.ae2023 {
 
    padding-top: 10rem;
    display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
background-color: #ffd642;
background-image: url("images/yellow-waves-background.png") !important; 
background-size: cover;
background-repeat: no-repeat;
height: 100vh; 

@include bp(mlarge) {
    padding: 0;
}

.row {
    background-image: url("images/yellow-waves-background.png"); 
    background-size: cover;
    margin-top: 20rem; 


    @include bp(smedium) {
        margin-top: 15rem; 
    }

    @include bp(medium) {
        margin-top: 0;
        background-image: none;
    }
}


body {
    overflow-y:hidden;

}

    
        .com-baforms-wrapper .ba-form-page .ba-form-field-item {
            margin:0;
        }
    }