/* Header
================================================== */
header {
     
    background-image: url("images/yellow-waves-background.png") !important; 
    background-size: cover;
    background-repeat: no-repeat;
    /*
        .row {
            align-items: center;
            text-align: center;
            padding: 2rem;
        } */
        .social {
            margin: 0 2rem 0 0;
        }
        }

        .header {

            .button {
                min-width: 220px;
                margin: 2rem 0.5rem 0.2rem 0.5rem;;
                
                @include bp(medium) { 
                    margin: 2rem 0.5rem;
                }
                }

            &__show {
            
            }

        &__left {
            display: none;
            @include bp(mlarge) {
            display: block;
            text-align: left;
            }
        }
        &__center {
            text-align: center;
            img {
            width: 500px;
            margin: 3rem 0;
            padding: 0 1.5rem;
            @include bp(mlarge) {
                padding: 0;
            } 
            }
        }
        &__right {
            @include bp(mlarge) {
            text-align: right;
            }
            p,
            a {
            margin: 0;
            }
            
        }

}




