.flex-wrap {
  flex-wrap: wrap;
}
.flex-nowrap {
  flex-wrap: nowrap;
}
.flex-align-items-top {
  align-items: flex-start;
}
.flex-align-items-center {
  align-items: center;
}
.flex-align-items-bottom {
  align-items: flex-end;
}
.flex-justify-left {
  justify-content: flex-start;
}
.flex-justify-center {
  justify-content: center;
}
.flex-justify-right {
  justify-content: flex-end;
}
.flex-space-around {
  justify-content: space-around;
}
.flex-space-between {
  justify-content: space-between;
}
.flex-row {
  flex-direction: row;
}
.flex-row-reverse {
  flex-direction: row-reverse;
}
.flex-column {
  flex-direction: column;
}
.flex-column-reverse {
  flex-direction: column-reverse;
}
