/* Components
================================================== */


/* Social
================================================== */
.social {
	ul {
		display: flex;
		flex-flow: row nowrap;
		justify-content: center;
		align-items: center;
		//max-width: 250px;
		margin: 0;
		padding: 0;
		list-style: none;

		li {
			width: auto;
			margin-bottom: 0;

			svg {
				width: 1.4em;
				height: 1.4em;

                * {
                    fill: $primary-colour;
                  }
                  &:hover {
                    * {
                      fill: $secondary-colour;
                      transition: $global-transition;
                    }
                  }

			}
    }
}
}