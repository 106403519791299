/* Sections 
================================================== */
section {
    position: relative;
    overflow: hidden;
    padding: 5rem 0;

}

.section {

    &--sponsors {
        .custom-shape-divider-top-1696249977 {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            overflow: hidden;
            line-height: 0;
        }
        
        .custom-shape-divider-top-1696249977 svg {
            position: relative;
            display: block;
            width: calc(165% + 1.3px);
            height: 143px;
        }
        
        .custom-shape-divider-top-1696249977 .shape-fill {
            fill: #FFFFFF;
        }
    }

}