.padding-left-0 {
  padding-left: 0;
}
.padding-left-0-5rem {
  padding-left: 0.5rem;
}
.padding-left-1rem {
  padding-left: 1rem;
}
.padding-left-1-5rem {
  padding-left: 1.5rem;
}
.padding-left-2rem {
  padding-left: 2rem;
}
.padding-left-3rem {
  padding-left: 3rem;
}
.padding-left-4rem {
  padding-left: 4rem;
}
.padding-left-5rem {
  padding-left: 5rem;
}
.padding-right-0 {
  padding-right: 0;
}
.padding-right-0-25rem {
  padding-right: 0.25rem;
}
.padding-right-0-5rem {
  padding-right: 0.5rem;
}
.padding-right-1rem {
  padding-right: 1rem;
}
.padding-right-1-5rem {
  padding-right: 1.5rem;
}
.padding-right-2rem {
  padding-right: 2rem;
}
.padding-right-3rem {
  padding-right: 3rem;
}
.padding-right-4rem {
  padding-right: 4rem;
}
.padding-right-5rem {
  padding-right: 5rem;
}

.margin-left-0 {
  margin-left: 0;
}
.margin-left-0-25rem {
  margin-left: 0.25rem;
}
.margin-left-0-5rem {
  margin-left: 0.5rem;
}
.margin-left-1rem {
  margin-left: 1rem;
}
.margin-left-1-5rem {
  margin-left: 1.5rem;
}
.margin-left-2rem {
  margin-left: 2rem;
}
.margin-left-3rem {
  margin-left: 3rem;
}
.margin-left-4rem {
  margin-left: 4rem;
}
.margin-left-5rem {
  margin-left: 5rem;
}
.margin-right-0 {
  margin-right: 0;
}
.margin-right-0-25rem {
  margin-right: 0.25rem;
}
.margin-right-0-5rem {
  margin-right: 0.5rem;
}
.margin-right-1rem {
  margin-right: 1rem;
}
.margin-right-1-5rem {
  margin-right: 1.5rem;
}
.margin-right-2rem {
  margin-right: 2rem;
}
.margin-right-3rem {
  margin-right: 3rem;
}
.margin-right-4rem {
  margin-right: 4rem;
}
.margin-right-5rem {
  margin-right: 5rem;
}
