.padding-0 {
  padding: 0;
}
.padding-0-25rem {
  padding: 0.25rem;
}
.padding-0-5rem {
  padding: 0.5rem;
}
.padding-1rem {
  padding: 1rem;
}
.padding-1-5rem {
  padding: 1.5rem;
}
.padding-2rem {
  padding: 2rem;
}
.padding-3rem {
  padding: 3rem;
}
.padding-4rem {
  padding: 4rem;
}
.padding-5rem {
  padding: 5rem;
}

.margin-0 {
  margin: 0;
}
.margin-0-25rem {
  margin: 0.25rem;
}
.margin-0-5rem {
  margin: 0.5rem;
}
.margin-1rem {
  margin: 1rem;
}
.margin-1-5rem {
  margin: 1.5rem;
}
.margin-2rem {
  margin: 2rem;
}
.margin-3rem {
  margin: 3rem;
}
.margin-4rem {
  margin: 4rem;
}
.margin-5rem {
  margin: 5rem;
}
