.padding-top-0 {
  padding-top: 0;
}
.padding-top-0-25rem {
  padding-top: 0.25rem;
}
.padding-top-0-5rem {
  padding-top: 0.5rem;
}
.padding-top-1rem {
  padding-top: 1rem;
}
.padding-top-1-5rem {
  padding-top: 1.5rem;
}
.padding-top-2rem {
  padding-top: 2rem;
}
.padding-top-3rem {
  padding-top: 3rem;
}
.padding-top-4rem {
  padding-top: 4rem;
}
.padding-top-5rem {
  padding-top: 5rem;
}
.padding-bottom-0 {
  padding-bottom: 0;
}
.padding-bottom-0-25rem {
  padding-bottom: 0.25rem;
}
.padding-bottom-0-5rem {
  padding-bottom: 0.5rem;
}
.padding-bottom-1rem {
  padding-bottom: 1rem;
}
.padding-bottom-1-5rem {
  padding-bottom: 1.5rem;
}
.padding-bottom-2rem {
  padding-bottom: 2rem;
}
.padding-bottom-3rem {
  padding-bottom: 3rem;
}
.padding-bottom-4rem {
  padding-bottom: 4rem;
}
.padding-bottom-5rem {
  padding-bottom: 5rem;
}

.margin-top-0 {
  margin-top: 0;
}
.margin-top-0-25rem {
  margin-top: 0.25rem;
}
.margin-top-0-5rem {
  margin-top: 0.5rem;
}
.margin-top-1rem {
  margin-top: 1rem;
}
.margin-top-1-5rem {
  margin-top: 1.5rem;
}
.margin-top-2rem {
  margin-top: 2rem;
}
.margin-top-3rem {
  margin-top: 3rem;
}
.margin-top-4rem {
  margin-top: 4rem;
}
.margin-top-5rem {
  margin-top: 5rem;
}
.margin-bottom-0 {
  margin-bottom: 0;
}
.margin-bottom-0-25rem {
  margin-bottom: 0.25rem;
}
.margin-bottom-0-5rem {
  margin-bottom: 0.5rem;
}
.margin-bottom-1rem {
  margin-bottom: 1rem;
}
.margin-bottom-1-5rem {
  margin-bottom: 1.5rem;
}
.margin-bottom-2rem {
  margin-bottom: 2rem;
}
.margin-bottom-3rem {
  margin-bottom: 3rem;
}
.margin-bottom-4rem {
  margin-bottom: 4rem;
}
.margin-bottom-5rem {
  margin-bottom: 5rem;
}
