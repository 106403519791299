@charset "UTF-8";
/* MAIN LESS FILE FOR COMPILING
--------------------------------------------------------------*/
/*--------------------------------------------------------------
1.0 Settings
--------------------------------------------------------------*/
@import url("https://use.typekit.net/byz2cbx.css");
@font-face {
  font-family: "bisonbold";
  src: url("../fonts/bison-bold-webfont.woff2") format("woff2"), url("../fonts/bison-bold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "bisonregular";
  src: url("../fonts/bison-regular-webfont.woff2") format("woff2"), url("../fonts/bison-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
/*--------------------------------------------------------------
2.0 Base
--------------------------------------------------------------*/
/* #Reset & Basics (Inspired by E. Meyers)
================================================== */
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
  position: relative;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

html {
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased; /* Fix for webkit rendering */
}

body {
  margin: 0;
}

.sr {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

pre,
code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1rem;
}

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring,
[type=button]:-moz-focusring,
[type=reset]:-moz-focusring,
[type=submit]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

fieldset {
  padding: 0.35em 0.75em 0.625em;
}

legend {
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

textarea {
  overflow: auto;
}

[type=checkbox],
[type=radio] {
  box-sizing: border-box;
  padding: 0;
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

/* Balbooa Forms Component 
================================================== */
.com-baforms-wrapper .ba-form-page .ba-form-field-item {
  margin: 0 0 1rem 0;
}
.com-baforms-wrapper .ba-form-page fieldset {
  padding: 0;
  border: none;
}
.com-baforms-wrapper .ba-form-page .ba-form-field-item .ba-form-products-cart .ba-form-product-quantity-cell input,
.com-baforms-wrapper .ba-form-page .ba-form-page-navigation-title,
.com-baforms-wrapper .ba-form-page .upload-file-btn,
.com-baforms-wrapper .ba-form-page .upload-file-input,
.com-baforms-wrapper .ba-form-page .ba-field-container select,
.com-baforms-wrapper .ba-form-page .ba-field-container textarea[readonly],
.com-baforms-wrapper .ba-form-page .ba-field-container textarea,
.com-baforms-wrapper .ba-form-page .ba-field-container input[type=text],
.com-baforms-wrapper .ba-form-page .ba-field-container input[type=number],
.com-baforms-wrapper .ba-form-page .ba-field-container input[type=password],
.com-baforms-wrapper .ba-form-page .ba-field-container input[type=email],
.com-baforms-wrapper .ba-form-page .ba-field-container select option,
.com-baforms-wrapper .ba-form-page .ba-form-field-item .ba-form-checkbox-wrapper .ba-checkbox-title {
  font-weight: normal;
  font-size: 1rem !important;
  color: #0f0f0f !important;
}
.com-baforms-wrapper .ba-form-page .ba-form-acceptance-field .ba-field-container {
  margin-top: 2rem;
}
.com-baforms-wrapper .ba-form-page .ba-form-submit-wrapper .ba-form-submit-btn-wrapper {
  width: auto !important;
}
.com-baforms-wrapper .ba-form-page .ba-form-submit-field {
  margin-bottom: 0 !important;
}
.com-baforms-wrapper .ba-form-page .ba-form-submit-btn {
  margin-top: 2rem;
  margin-bottom: 0;
}

.table-wrap {
  width: 100%;
  overflow-x: auto;
}

table {
  text-align: left;
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  margin: 0;
  border: 1px solid #ffffff;
}
table td,
table th {
  padding: 1rem 1.5rem;
  border-bottom: 1px solid #ffffff;
  border-right: 1px solid #ffffff;
}
table td *,
table th * {
  margin: 0;
}
table th {
  background-color: #ed2e69;
  font-size: 1.1rem;
}
table th * {
  color: #ffffff;
}

table tr:nth-child(2n) {
  background-color: #edf0ef;
}

img {
  max-width: 100%;
  height: auto;
}

body figure[data-wf-figure] {
  display: block;
}

.embed-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
}
.embed-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

body {
  font-family: "degular-regular", sans-serif;
  font-size: 1.35rem;
  line-height: 1.2;
  color: #0f0f0f;
  font-weight: 400;
  background: #ffffff;
}

main,
details {
  display: block;
}

a {
  color: #ed2e69;
  background-color: transparent;
  text-decoration: none;
  -webkit-transition: 400ms ease-in-out;
  -moz-transition: 400ms ease-in-out;
  -ms-transition: 400ms ease-in-out;
  -o-transition: 400ms ease-in-out;
  transition: 400ms ease-in-out;
}
a:hover {
  color: #0f0f0f;
}

b,
.b,
strong,
.strong {
  font-weight: 700;
}

big,
.big {
  font-size: 2.5rem;
}

em,
.em {
  font-style: italic;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

abbr[title] {
  border-bottom: none;
  text-decoration: underline;
  text-decoration: underline dotted;
}

small,
.small {
  font-size: 0.8125rem;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

img {
  border-style: none;
}

template,
[hidden] {
  display: none;
}

li {
  list-style: disc;
  margin-left: 20px;
  line-height: 1.2em;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "bisonbold", sans-serif;
  line-height: 1.2;
  font-weight: 400;
  margin: 1.5rem 0 2rem;
  text-transform: uppercase;
}

h1,
.h1 {
  font-size: 5.6rem;
  font-weight: 900;
}

h2,
.h2 {
  font-size: 4.2rem;
}

h3,
.h3 {
  font-size: 3.8rem;
}

h4,
.h4 {
  font-size: 3.4rem;
  /*@media screen and (max-width: 39.9375em) {
    font-size: 1.6rem;
  }*/
}

h5,
.h5 {
  font-size: 3rem;
}

h6,
.h6 {
  font-size: 2.8rem;
}

.lite {
  font-family: "bisonregular", sans-serif;
  font-weight: 300;
}

.button,
button,
input[type=submit],
input[type=reset],
input[type=button] {
  display: inline-flex;
  flex-direction: column;
  margin-bottom: 1.5rem;
  padding: 1rem 2em;
  background: #ed2e69;
  border: 0;
  color: #ffffff;
  font-size: 1.2rem;
  font-weight: normal;
  text-decoration: none;
  line-height: normal;
  font-family: "degular-bold", sans-serif;
  font-weight: bold;
  text-align: center;
  cursor: pointer;
  outline: none;
  border-radius: 0px;
  -webkit-transition: 400ms ease-in-out;
  -moz-transition: 400ms ease-in-out;
  -ms-transition: 400ms ease-in-out;
  -o-transition: 400ms ease-in-out;
  transition: 400ms ease-in-out;
  -webkit-appearance: none;
}
.button:hover,
button:hover,
input[type=submit]:hover,
input[type=reset]:hover,
input[type=button]:hover {
  color: #ffffff;
  background: #df1352;
  border: 0;
}
.button:active,
button:active,
input[type=submit]:active,
input[type=reset]:active,
input[type=button]:active {
  border: 0;
}
.button:visited,
button:visited,
input[type=submit]:visited,
input[type=reset]:visited,
input[type=button]:visited {
  color: #ffffff;
}

.button--full-width {
  width: 100%;
  padding-left: 0 !important;
  padding-right: 0 !important;
  text-align: center;
}
.button--secondary {
  background: #ed2e69;
}
.button--secondary:hover {
  background: #df1352;
}
.button-wrap--center {
  text-align: center;
}

/* Fix for odd Mozilla border & padding issues */
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

.padding-left-0 {
  padding-left: 0;
}

.padding-left-0-5rem {
  padding-left: 0.5rem;
}

.padding-left-1rem {
  padding-left: 1rem;
}

.padding-left-1-5rem {
  padding-left: 1.5rem;
}

.padding-left-2rem {
  padding-left: 2rem;
}

.padding-left-3rem {
  padding-left: 3rem;
}

.padding-left-4rem {
  padding-left: 4rem;
}

.padding-left-5rem {
  padding-left: 5rem;
}

.padding-right-0 {
  padding-right: 0;
}

.padding-right-0-25rem {
  padding-right: 0.25rem;
}

.padding-right-0-5rem {
  padding-right: 0.5rem;
}

.padding-right-1rem {
  padding-right: 1rem;
}

.padding-right-1-5rem {
  padding-right: 1.5rem;
}

.padding-right-2rem {
  padding-right: 2rem;
}

.padding-right-3rem {
  padding-right: 3rem;
}

.padding-right-4rem {
  padding-right: 4rem;
}

.padding-right-5rem {
  padding-right: 5rem;
}

.margin-left-0 {
  margin-left: 0;
}

.margin-left-0-25rem {
  margin-left: 0.25rem;
}

.margin-left-0-5rem {
  margin-left: 0.5rem;
}

.margin-left-1rem {
  margin-left: 1rem;
}

.margin-left-1-5rem {
  margin-left: 1.5rem;
}

.margin-left-2rem {
  margin-left: 2rem;
}

.margin-left-3rem {
  margin-left: 3rem;
}

.margin-left-4rem {
  margin-left: 4rem;
}

.margin-left-5rem {
  margin-left: 5rem;
}

.margin-right-0 {
  margin-right: 0;
}

.margin-right-0-25rem {
  margin-right: 0.25rem;
}

.margin-right-0-5rem {
  margin-right: 0.5rem;
}

.margin-right-1rem {
  margin-right: 1rem;
}

.margin-right-1-5rem {
  margin-right: 1.5rem;
}

.margin-right-2rem {
  margin-right: 2rem;
}

.margin-right-3rem {
  margin-right: 3rem;
}

.margin-right-4rem {
  margin-right: 4rem;
}

.margin-right-5rem {
  margin-right: 5rem;
}

.padding-top-0 {
  padding-top: 0;
}

.padding-top-0-25rem {
  padding-top: 0.25rem;
}

.padding-top-0-5rem {
  padding-top: 0.5rem;
}

.padding-top-1rem {
  padding-top: 1rem;
}

.padding-top-1-5rem {
  padding-top: 1.5rem;
}

.padding-top-2rem {
  padding-top: 2rem;
}

.padding-top-3rem {
  padding-top: 3rem;
}

.padding-top-4rem {
  padding-top: 4rem;
}

.padding-top-5rem {
  padding-top: 5rem;
}

.padding-bottom-0 {
  padding-bottom: 0;
}

.padding-bottom-0-25rem {
  padding-bottom: 0.25rem;
}

.padding-bottom-0-5rem {
  padding-bottom: 0.5rem;
}

.padding-bottom-1rem {
  padding-bottom: 1rem;
}

.padding-bottom-1-5rem {
  padding-bottom: 1.5rem;
}

.padding-bottom-2rem {
  padding-bottom: 2rem;
}

.padding-bottom-3rem {
  padding-bottom: 3rem;
}

.padding-bottom-4rem {
  padding-bottom: 4rem;
}

.padding-bottom-5rem {
  padding-bottom: 5rem;
}

.margin-top-0 {
  margin-top: 0;
}

.margin-top-0-25rem {
  margin-top: 0.25rem;
}

.margin-top-0-5rem {
  margin-top: 0.5rem;
}

.margin-top-1rem {
  margin-top: 1rem;
}

.margin-top-1-5rem {
  margin-top: 1.5rem;
}

.margin-top-2rem {
  margin-top: 2rem;
}

.margin-top-3rem {
  margin-top: 3rem;
}

.margin-top-4rem {
  margin-top: 4rem;
}

.margin-top-5rem {
  margin-top: 5rem;
}

.margin-bottom-0 {
  margin-bottom: 0;
}

.margin-bottom-0-25rem {
  margin-bottom: 0.25rem;
}

.margin-bottom-0-5rem {
  margin-bottom: 0.5rem;
}

.margin-bottom-1rem {
  margin-bottom: 1rem;
}

.margin-bottom-1-5rem {
  margin-bottom: 1.5rem;
}

.margin-bottom-2rem {
  margin-bottom: 2rem;
}

.margin-bottom-3rem {
  margin-bottom: 3rem;
}

.margin-bottom-4rem {
  margin-bottom: 4rem;
}

.margin-bottom-5rem {
  margin-bottom: 5rem;
}

.padding-0 {
  padding: 0;
}

.padding-0-25rem {
  padding: 0.25rem;
}

.padding-0-5rem {
  padding: 0.5rem;
}

.padding-1rem {
  padding: 1rem;
}

.padding-1-5rem {
  padding: 1.5rem;
}

.padding-2rem {
  padding: 2rem;
}

.padding-3rem {
  padding: 3rem;
}

.padding-4rem {
  padding: 4rem;
}

.padding-5rem {
  padding: 5rem;
}

.margin-0 {
  margin: 0;
}

.margin-0-25rem {
  margin: 0.25rem;
}

.margin-0-5rem {
  margin: 0.5rem;
}

.margin-1rem {
  margin: 1rem;
}

.margin-1-5rem {
  margin: 1.5rem;
}

.margin-2rem {
  margin: 2rem;
}

.margin-3rem {
  margin: 3rem;
}

.margin-4rem {
  margin: 4rem;
}

.margin-5rem {
  margin: 5rem;
}

.none {
  display: none;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.inline {
  display: inline;
}

.flex {
  display: flex;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-nowrap {
  flex-wrap: nowrap;
}

.flex-align-items-top {
  align-items: flex-start;
}

.flex-align-items-center {
  align-items: center;
}

.flex-align-items-bottom {
  align-items: flex-end;
}

.flex-justify-left {
  justify-content: flex-start;
}

.flex-justify-center {
  justify-content: center;
}

.flex-justify-right {
  justify-content: flex-end;
}

.flex-space-around {
  justify-content: space-around;
}

.flex-space-between {
  justify-content: space-between;
}

.flex-row {
  flex-direction: row;
}

.flex-row-reverse {
  flex-direction: row-reverse;
}

.flex-column {
  flex-direction: column;
}

.flex-column-reverse {
  flex-direction: column-reverse;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.static {
  position: static;
}

.fixed {
  position: fixed;
}

.sticky {
  position: sticky;
}

.no-select {
  user-select: none;
  cursor: default;
}

.font-100 {
  font-weight: 100;
}

.font-200 {
  font-weight: 200;
}

.font-300 {
  font-weight: 300;
}

.font-400 {
  font-weight: 400;
}

.font-500 {
  font-weight: 500;
}

.font-600 {
  font-weight: 600;
}

.font-700 {
  font-weight: 700;
}

.font-800 {
  font-weight: 800;
}

.font-900 {
  font-weight: 900;
}

.uppercase {
  text-transform: uppercase;
}

.lowercase {
  text-transform: lowercase;
}

.capitalize {
  text-transform: capitalize;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

/*--------------------------------------------------------------
3.0 Foundation
--------------------------------------------------------------*/
/**
 * Foundation for Sites
 * Version 6.7.3
 * https://get.foundation
 * Licensed under MIT Open Source
 */
/* either include all foundation components…  //*/
/* or include specific foundation components //*/
/* include either foundation-grid… //*/
/* or foundation-flex-grid (but don't include both) //*/
.row {
  max-width: 1280px;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-flow: row wrap;
}
.row .row {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}
@media print, screen and (min-width: 30em) {
  .row .row {
    margin-right: -0.625rem;
    margin-left: -0.625rem;
  }
}
@media print, screen and (min-width: 36em) {
  .row .row {
    margin-right: -0.9375rem;
    margin-left: -0.9375rem;
  }
}
@media print, screen and (min-width: 48em) {
  .row .row {
    margin-right: -0.9375rem;
    margin-left: -0.9375rem;
  }
}
@media print, screen and (min-width: 60em) {
  .row .row {
    margin-right: -0.9375rem;
    margin-left: -0.9375rem;
  }
}
@media screen and (min-width: 80em) {
  .row .row {
    margin-right: -0.9375rem;
    margin-left: -0.9375rem;
  }
}
@media screen and (min-width: 80em) {
  .row .row {
    margin-right: -0.9375rem;
    margin-left: -0.9375rem;
  }
}
@media screen and (min-width: 80em) {
  .row .row {
    margin-right: -0.9375rem;
    margin-left: -0.9375rem;
  }
}
.row .row.collapse {
  margin-right: 0;
  margin-left: 0;
}
.row.expanded {
  max-width: none;
}
.row.expanded .row {
  margin-right: auto;
  margin-left: auto;
}
.row:not(.expanded) .row {
  max-width: none;
}
.row.collapse > .column, .row.collapse > .columns, .newslist .row.collapse > .latestnews-item {
  padding-right: 0;
  padding-left: 0;
}
.row.is-collapse-child, .row.collapse > .column > .row, .row.collapse > .columns > .row, .newslist .row.collapse > .latestnews-item > .row {
  margin-right: 0;
  margin-left: 0;
}

.column, .columns, .newslist .latestnews-item {
  flex: 1 1 0px;
  padding-right: 0.625rem;
  padding-left: 0.625rem;
  min-width: 0;
}
@media print, screen and (min-width: 36em) {
  .column, .columns, .newslist .latestnews-item {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}

.column.row.row, .row.row.columns, .newslist .row.row.latestnews-item {
  float: none;
  display: block;
}

.row .column.row.row, .row .row.row.columns, .row .newslist .row.row.latestnews-item, .newslist .row .row.row.latestnews-item {
  margin-right: 0;
  margin-left: 0;
  padding-right: 0;
  padding-left: 0;
}

.small-1 {
  flex: 0 0 8.3333333333%;
  max-width: 8.3333333333%;
}

.small-offset-0 {
  margin-left: 0%;
}

.small-2 {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.small-offset-1 {
  margin-left: 8.3333333333%;
}

.small-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.small-offset-2 {
  margin-left: 16.6666666667%;
}

.small-4 {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.small-offset-3 {
  margin-left: 25%;
}

.small-5 {
  flex: 0 0 41.6666666667%;
  max-width: 41.6666666667%;
}

.small-offset-4 {
  margin-left: 33.3333333333%;
}

.small-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.small-offset-5 {
  margin-left: 41.6666666667%;
}

.small-7 {
  flex: 0 0 58.3333333333%;
  max-width: 58.3333333333%;
}

.small-offset-6 {
  margin-left: 50%;
}

.small-8 {
  flex: 0 0 66.6666666667%;
  max-width: 66.6666666667%;
}

.small-offset-7 {
  margin-left: 58.3333333333%;
}

.small-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.small-offset-8 {
  margin-left: 66.6666666667%;
}

.small-10 {
  flex: 0 0 83.3333333333%;
  max-width: 83.3333333333%;
}

.small-offset-9 {
  margin-left: 75%;
}

.small-11 {
  flex: 0 0 91.6666666667%;
  max-width: 91.6666666667%;
}

.small-offset-10 {
  margin-left: 83.3333333333%;
}

.small-12, .newslist .latestnews-item {
  flex: 0 0 100%;
  max-width: 100%;
}

.small-offset-11 {
  margin-left: 91.6666666667%;
}

.small-up-1 {
  flex-wrap: wrap;
}
.small-up-1 > .column, .small-up-1 > .columns, .newslist .small-up-1 > .latestnews-item {
  flex: 0 0 100%;
  max-width: 100%;
}

.small-up-2 {
  flex-wrap: wrap;
}
.small-up-2 > .column, .small-up-2 > .columns, .newslist .small-up-2 > .latestnews-item {
  flex: 0 0 50%;
  max-width: 50%;
}

.small-up-3 {
  flex-wrap: wrap;
}
.small-up-3 > .column, .small-up-3 > .columns, .newslist .small-up-3 > .latestnews-item {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.small-up-4 {
  flex-wrap: wrap;
}
.small-up-4 > .column, .small-up-4 > .columns, .newslist .small-up-4 > .latestnews-item {
  flex: 0 0 25%;
  max-width: 25%;
}

.small-up-5 {
  flex-wrap: wrap;
}
.small-up-5 > .column, .small-up-5 > .columns, .newslist .small-up-5 > .latestnews-item {
  flex: 0 0 20%;
  max-width: 20%;
}

.small-up-6 {
  flex-wrap: wrap;
}
.small-up-6 > .column, .small-up-6 > .columns, .newslist .small-up-6 > .latestnews-item {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.small-up-7 {
  flex-wrap: wrap;
}
.small-up-7 > .column, .small-up-7 > .columns, .newslist .small-up-7 > .latestnews-item {
  flex: 0 0 14.2857142857%;
  max-width: 14.2857142857%;
}

.small-up-8 {
  flex-wrap: wrap;
}
.small-up-8 > .column, .small-up-8 > .columns, .newslist .small-up-8 > .latestnews-item {
  flex: 0 0 12.5%;
  max-width: 12.5%;
}

.small-collapse > .column, .small-collapse > .columns, .newslist .small-collapse > .latestnews-item {
  padding-right: 0;
  padding-left: 0;
}

.small-uncollapse > .column, .small-uncollapse > .columns, .newslist .small-uncollapse > .latestnews-item {
  padding-right: 0.625rem;
  padding-left: 0.625rem;
}

@media print, screen and (min-width: 30em) {
  .smedium-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .smedium-offset-0 {
    margin-left: 0%;
  }
  .smedium-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .smedium-offset-1 {
    margin-left: 8.3333333333%;
  }
  .smedium-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .smedium-offset-2 {
    margin-left: 16.6666666667%;
  }
  .smedium-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .smedium-offset-3 {
    margin-left: 25%;
  }
  .smedium-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .smedium-offset-4 {
    margin-left: 33.3333333333%;
  }
  .smedium-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .smedium-offset-5 {
    margin-left: 41.6666666667%;
  }
  .smedium-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .smedium-offset-6 {
    margin-left: 50%;
  }
  .smedium-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .smedium-offset-7 {
    margin-left: 58.3333333333%;
  }
  .smedium-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .smedium-offset-8 {
    margin-left: 66.6666666667%;
  }
  .smedium-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .smedium-offset-9 {
    margin-left: 75%;
  }
  .smedium-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .smedium-offset-10 {
    margin-left: 83.3333333333%;
  }
  .smedium-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .smedium-offset-11 {
    margin-left: 91.6666666667%;
  }
  .smedium-up-1 {
    flex-wrap: wrap;
  }
  .smedium-up-1 > .column, .smedium-up-1 > .columns, .newslist .smedium-up-1 > .latestnews-item {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .smedium-up-2 {
    flex-wrap: wrap;
  }
  .smedium-up-2 > .column, .smedium-up-2 > .columns, .newslist .smedium-up-2 > .latestnews-item {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .smedium-up-3 {
    flex-wrap: wrap;
  }
  .smedium-up-3 > .column, .smedium-up-3 > .columns, .newslist .smedium-up-3 > .latestnews-item {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .smedium-up-4 {
    flex-wrap: wrap;
  }
  .smedium-up-4 > .column, .smedium-up-4 > .columns, .newslist .smedium-up-4 > .latestnews-item {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .smedium-up-5 {
    flex-wrap: wrap;
  }
  .smedium-up-5 > .column, .smedium-up-5 > .columns, .newslist .smedium-up-5 > .latestnews-item {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .smedium-up-6 {
    flex-wrap: wrap;
  }
  .smedium-up-6 > .column, .smedium-up-6 > .columns, .newslist .smedium-up-6 > .latestnews-item {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .smedium-up-7 {
    flex-wrap: wrap;
  }
  .smedium-up-7 > .column, .smedium-up-7 > .columns, .newslist .smedium-up-7 > .latestnews-item {
    flex: 0 0 14.2857142857%;
    max-width: 14.2857142857%;
  }
  .smedium-up-8 {
    flex-wrap: wrap;
  }
  .smedium-up-8 > .column, .smedium-up-8 > .columns, .newslist .smedium-up-8 > .latestnews-item {
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
}
@media print, screen and (min-width: 30em) and (min-width: 30em) {
  .smedium-expand {
    flex: 1 1 0px;
  }
}
.row.smedium-unstack > .column, .row.smedium-unstack > .columns, .newslist .row.smedium-unstack > .latestnews-item {
  flex: 0 0 100%;
}
@media print, screen and (min-width: 30em) {
  .row.smedium-unstack > .column, .row.smedium-unstack > .columns, .newslist .row.smedium-unstack > .latestnews-item {
    flex: 1 1 0px;
  }
  .smedium-collapse > .column, .smedium-collapse > .columns, .newslist .smedium-collapse > .latestnews-item {
    padding-right: 0;
    padding-left: 0;
  }
  .smedium-uncollapse > .column, .smedium-uncollapse > .columns, .newslist .smedium-uncollapse > .latestnews-item {
    padding-right: 0.625rem;
    padding-left: 0.625rem;
  }
}

@media print, screen and (min-width: 36em) {
  .medium-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .medium-offset-0 {
    margin-left: 0%;
  }
  .medium-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .medium-offset-1 {
    margin-left: 8.3333333333%;
  }
  .medium-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .medium-offset-2 {
    margin-left: 16.6666666667%;
  }
  .medium-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .medium-offset-3 {
    margin-left: 25%;
  }
  .medium-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .medium-offset-4 {
    margin-left: 33.3333333333%;
  }
  .medium-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .medium-offset-5 {
    margin-left: 41.6666666667%;
  }
  .medium-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .medium-offset-6 {
    margin-left: 50%;
  }
  .medium-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .medium-offset-7 {
    margin-left: 58.3333333333%;
  }
  .medium-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .medium-offset-8 {
    margin-left: 66.6666666667%;
  }
  .medium-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .medium-offset-9 {
    margin-left: 75%;
  }
  .medium-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .medium-offset-10 {
    margin-left: 83.3333333333%;
  }
  .medium-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .medium-offset-11 {
    margin-left: 91.6666666667%;
  }
  .medium-up-1 {
    flex-wrap: wrap;
  }
  .medium-up-1 > .column, .medium-up-1 > .columns, .newslist .medium-up-1 > .latestnews-item {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .medium-up-2 {
    flex-wrap: wrap;
  }
  .medium-up-2 > .column, .medium-up-2 > .columns, .newslist .medium-up-2 > .latestnews-item {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .medium-up-3 {
    flex-wrap: wrap;
  }
  .medium-up-3 > .column, .medium-up-3 > .columns, .newslist .medium-up-3 > .latestnews-item {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .medium-up-4 {
    flex-wrap: wrap;
  }
  .medium-up-4 > .column, .medium-up-4 > .columns, .newslist .medium-up-4 > .latestnews-item {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .medium-up-5 {
    flex-wrap: wrap;
  }
  .medium-up-5 > .column, .medium-up-5 > .columns, .newslist .medium-up-5 > .latestnews-item {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .medium-up-6 {
    flex-wrap: wrap;
  }
  .medium-up-6 > .column, .medium-up-6 > .columns, .newslist .medium-up-6 > .latestnews-item {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .medium-up-7 {
    flex-wrap: wrap;
  }
  .medium-up-7 > .column, .medium-up-7 > .columns, .newslist .medium-up-7 > .latestnews-item {
    flex: 0 0 14.2857142857%;
    max-width: 14.2857142857%;
  }
  .medium-up-8 {
    flex-wrap: wrap;
  }
  .medium-up-8 > .column, .medium-up-8 > .columns, .newslist .medium-up-8 > .latestnews-item {
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
}
@media print, screen and (min-width: 36em) and (min-width: 36em) {
  .medium-expand {
    flex: 1 1 0px;
  }
}
.row.medium-unstack > .column, .row.medium-unstack > .columns, .newslist .row.medium-unstack > .latestnews-item {
  flex: 0 0 100%;
}
@media print, screen and (min-width: 36em) {
  .row.medium-unstack > .column, .row.medium-unstack > .columns, .newslist .row.medium-unstack > .latestnews-item {
    flex: 1 1 0px;
  }
  .medium-collapse > .column, .medium-collapse > .columns, .newslist .medium-collapse > .latestnews-item {
    padding-right: 0;
    padding-left: 0;
  }
  .medium-uncollapse > .column, .medium-uncollapse > .columns, .newslist .medium-uncollapse > .latestnews-item {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}

@media print, screen and (min-width: 48em) {
  .mlarge-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .mlarge-offset-0 {
    margin-left: 0%;
  }
  .mlarge-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .mlarge-offset-1 {
    margin-left: 8.3333333333%;
  }
  .mlarge-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .mlarge-offset-2 {
    margin-left: 16.6666666667%;
  }
  .mlarge-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .mlarge-offset-3 {
    margin-left: 25%;
  }
  .mlarge-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .mlarge-offset-4 {
    margin-left: 33.3333333333%;
  }
  .mlarge-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .mlarge-offset-5 {
    margin-left: 41.6666666667%;
  }
  .mlarge-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .mlarge-offset-6 {
    margin-left: 50%;
  }
  .mlarge-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .mlarge-offset-7 {
    margin-left: 58.3333333333%;
  }
  .mlarge-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .mlarge-offset-8 {
    margin-left: 66.6666666667%;
  }
  .mlarge-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .mlarge-offset-9 {
    margin-left: 75%;
  }
  .mlarge-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .mlarge-offset-10 {
    margin-left: 83.3333333333%;
  }
  .mlarge-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .mlarge-offset-11 {
    margin-left: 91.6666666667%;
  }
  .mlarge-up-1 {
    flex-wrap: wrap;
  }
  .mlarge-up-1 > .column, .mlarge-up-1 > .columns, .newslist .mlarge-up-1 > .latestnews-item {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .mlarge-up-2 {
    flex-wrap: wrap;
  }
  .mlarge-up-2 > .column, .mlarge-up-2 > .columns, .newslist .mlarge-up-2 > .latestnews-item {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .mlarge-up-3 {
    flex-wrap: wrap;
  }
  .mlarge-up-3 > .column, .mlarge-up-3 > .columns, .newslist .mlarge-up-3 > .latestnews-item {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .mlarge-up-4 {
    flex-wrap: wrap;
  }
  .mlarge-up-4 > .column, .mlarge-up-4 > .columns, .newslist .mlarge-up-4 > .latestnews-item {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .mlarge-up-5 {
    flex-wrap: wrap;
  }
  .mlarge-up-5 > .column, .mlarge-up-5 > .columns, .newslist .mlarge-up-5 > .latestnews-item {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .mlarge-up-6 {
    flex-wrap: wrap;
  }
  .mlarge-up-6 > .column, .mlarge-up-6 > .columns, .newslist .mlarge-up-6 > .latestnews-item {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .mlarge-up-7 {
    flex-wrap: wrap;
  }
  .mlarge-up-7 > .column, .mlarge-up-7 > .columns, .newslist .mlarge-up-7 > .latestnews-item {
    flex: 0 0 14.2857142857%;
    max-width: 14.2857142857%;
  }
  .mlarge-up-8 {
    flex-wrap: wrap;
  }
  .mlarge-up-8 > .column, .mlarge-up-8 > .columns, .newslist .mlarge-up-8 > .latestnews-item {
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
}
@media print, screen and (min-width: 48em) and (min-width: 48em) {
  .mlarge-expand {
    flex: 1 1 0px;
  }
}
.row.mlarge-unstack > .column, .row.mlarge-unstack > .columns, .newslist .row.mlarge-unstack > .latestnews-item {
  flex: 0 0 100%;
}
@media print, screen and (min-width: 48em) {
  .row.mlarge-unstack > .column, .row.mlarge-unstack > .columns, .newslist .row.mlarge-unstack > .latestnews-item {
    flex: 1 1 0px;
  }
  .mlarge-collapse > .column, .mlarge-collapse > .columns, .newslist .mlarge-collapse > .latestnews-item {
    padding-right: 0;
    padding-left: 0;
  }
  .mlarge-uncollapse > .column, .mlarge-uncollapse > .columns, .newslist .mlarge-uncollapse > .latestnews-item {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}

@media print, screen and (min-width: 60em) {
  .large-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .large-offset-0 {
    margin-left: 0%;
  }
  .large-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .large-offset-1 {
    margin-left: 8.3333333333%;
  }
  .large-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .large-offset-2 {
    margin-left: 16.6666666667%;
  }
  .large-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .large-offset-3 {
    margin-left: 25%;
  }
  .large-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .large-offset-4 {
    margin-left: 33.3333333333%;
  }
  .large-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .large-offset-5 {
    margin-left: 41.6666666667%;
  }
  .large-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .large-offset-6 {
    margin-left: 50%;
  }
  .large-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .large-offset-7 {
    margin-left: 58.3333333333%;
  }
  .large-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .large-offset-8 {
    margin-left: 66.6666666667%;
  }
  .large-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .large-offset-9 {
    margin-left: 75%;
  }
  .large-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .large-offset-10 {
    margin-left: 83.3333333333%;
  }
  .large-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .large-offset-11 {
    margin-left: 91.6666666667%;
  }
  .large-up-1 {
    flex-wrap: wrap;
  }
  .large-up-1 > .column, .large-up-1 > .columns, .newslist .large-up-1 > .latestnews-item {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .large-up-2 {
    flex-wrap: wrap;
  }
  .large-up-2 > .column, .large-up-2 > .columns, .newslist .large-up-2 > .latestnews-item {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .large-up-3 {
    flex-wrap: wrap;
  }
  .large-up-3 > .column, .large-up-3 > .columns, .newslist .large-up-3 > .latestnews-item {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .large-up-4 {
    flex-wrap: wrap;
  }
  .large-up-4 > .column, .large-up-4 > .columns, .newslist .large-up-4 > .latestnews-item {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .large-up-5 {
    flex-wrap: wrap;
  }
  .large-up-5 > .column, .large-up-5 > .columns, .newslist .large-up-5 > .latestnews-item {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .large-up-6 {
    flex-wrap: wrap;
  }
  .large-up-6 > .column, .large-up-6 > .columns, .newslist .large-up-6 > .latestnews-item {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .large-up-7 {
    flex-wrap: wrap;
  }
  .large-up-7 > .column, .large-up-7 > .columns, .newslist .large-up-7 > .latestnews-item {
    flex: 0 0 14.2857142857%;
    max-width: 14.2857142857%;
  }
  .large-up-8 {
    flex-wrap: wrap;
  }
  .large-up-8 > .column, .large-up-8 > .columns, .newslist .large-up-8 > .latestnews-item {
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
}
@media print, screen and (min-width: 60em) and (min-width: 60em) {
  .large-expand {
    flex: 1 1 0px;
  }
}
.row.large-unstack > .column, .row.large-unstack > .columns, .newslist .row.large-unstack > .latestnews-item {
  flex: 0 0 100%;
}
@media print, screen and (min-width: 60em) {
  .row.large-unstack > .column, .row.large-unstack > .columns, .newslist .row.large-unstack > .latestnews-item {
    flex: 1 1 0px;
  }
  .large-collapse > .column, .large-collapse > .columns, .newslist .large-collapse > .latestnews-item {
    padding-right: 0;
    padding-left: 0;
  }
  .large-uncollapse > .column, .large-uncollapse > .columns, .newslist .large-uncollapse > .latestnews-item {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}

@media screen and (min-width: 80em) {
  .xlarge-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .xlarge-offset-0 {
    margin-left: 0%;
  }
  .xlarge-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .xlarge-offset-1 {
    margin-left: 8.3333333333%;
  }
  .xlarge-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .xlarge-offset-2 {
    margin-left: 16.6666666667%;
  }
  .xlarge-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .xlarge-offset-3 {
    margin-left: 25%;
  }
  .xlarge-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .xlarge-offset-4 {
    margin-left: 33.3333333333%;
  }
  .xlarge-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .xlarge-offset-5 {
    margin-left: 41.6666666667%;
  }
  .xlarge-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .xlarge-offset-6 {
    margin-left: 50%;
  }
  .xlarge-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .xlarge-offset-7 {
    margin-left: 58.3333333333%;
  }
  .xlarge-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .xlarge-offset-8 {
    margin-left: 66.6666666667%;
  }
  .xlarge-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .xlarge-offset-9 {
    margin-left: 75%;
  }
  .xlarge-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .xlarge-offset-10 {
    margin-left: 83.3333333333%;
  }
  .xlarge-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .xlarge-offset-11 {
    margin-left: 91.6666666667%;
  }
  .xlarge-up-1 {
    flex-wrap: wrap;
  }
  .xlarge-up-1 > .column, .xlarge-up-1 > .columns, .newslist .xlarge-up-1 > .latestnews-item {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .xlarge-up-2 {
    flex-wrap: wrap;
  }
  .xlarge-up-2 > .column, .xlarge-up-2 > .columns, .newslist .xlarge-up-2 > .latestnews-item {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .xlarge-up-3 {
    flex-wrap: wrap;
  }
  .xlarge-up-3 > .column, .xlarge-up-3 > .columns, .newslist .xlarge-up-3 > .latestnews-item {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .xlarge-up-4 {
    flex-wrap: wrap;
  }
  .xlarge-up-4 > .column, .xlarge-up-4 > .columns, .newslist .xlarge-up-4 > .latestnews-item {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .xlarge-up-5 {
    flex-wrap: wrap;
  }
  .xlarge-up-5 > .column, .xlarge-up-5 > .columns, .newslist .xlarge-up-5 > .latestnews-item {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .xlarge-up-6 {
    flex-wrap: wrap;
  }
  .xlarge-up-6 > .column, .xlarge-up-6 > .columns, .newslist .xlarge-up-6 > .latestnews-item {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .xlarge-up-7 {
    flex-wrap: wrap;
  }
  .xlarge-up-7 > .column, .xlarge-up-7 > .columns, .newslist .xlarge-up-7 > .latestnews-item {
    flex: 0 0 14.2857142857%;
    max-width: 14.2857142857%;
  }
  .xlarge-up-8 {
    flex-wrap: wrap;
  }
  .xlarge-up-8 > .column, .xlarge-up-8 > .columns, .newslist .xlarge-up-8 > .latestnews-item {
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
}
@media screen and (min-width: 80em) and (min-width: 80em) {
  .xlarge-expand {
    flex: 1 1 0px;
  }
}
.row.xlarge-unstack > .column, .row.xlarge-unstack > .columns, .newslist .row.xlarge-unstack > .latestnews-item {
  flex: 0 0 100%;
}
@media screen and (min-width: 80em) {
  .row.xlarge-unstack > .column, .row.xlarge-unstack > .columns, .newslist .row.xlarge-unstack > .latestnews-item {
    flex: 1 1 0px;
  }
  .xlarge-collapse > .column, .xlarge-collapse > .columns, .newslist .xlarge-collapse > .latestnews-item {
    padding-right: 0;
    padding-left: 0;
  }
  .xlarge-uncollapse > .column, .xlarge-uncollapse > .columns, .newslist .xlarge-uncollapse > .latestnews-item {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}

@media screen and (min-width: 80em) {
  .xxlarge-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .xxlarge-offset-0 {
    margin-left: 0%;
  }
  .xxlarge-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .xxlarge-offset-1 {
    margin-left: 8.3333333333%;
  }
  .xxlarge-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .xxlarge-offset-2 {
    margin-left: 16.6666666667%;
  }
  .xxlarge-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .xxlarge-offset-3 {
    margin-left: 25%;
  }
  .xxlarge-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .xxlarge-offset-4 {
    margin-left: 33.3333333333%;
  }
  .xxlarge-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .xxlarge-offset-5 {
    margin-left: 41.6666666667%;
  }
  .xxlarge-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .xxlarge-offset-6 {
    margin-left: 50%;
  }
  .xxlarge-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .xxlarge-offset-7 {
    margin-left: 58.3333333333%;
  }
  .xxlarge-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .xxlarge-offset-8 {
    margin-left: 66.6666666667%;
  }
  .xxlarge-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .xxlarge-offset-9 {
    margin-left: 75%;
  }
  .xxlarge-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .xxlarge-offset-10 {
    margin-left: 83.3333333333%;
  }
  .xxlarge-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .xxlarge-offset-11 {
    margin-left: 91.6666666667%;
  }
  .xxlarge-up-1 {
    flex-wrap: wrap;
  }
  .xxlarge-up-1 > .column, .xxlarge-up-1 > .columns, .newslist .xxlarge-up-1 > .latestnews-item {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .xxlarge-up-2 {
    flex-wrap: wrap;
  }
  .xxlarge-up-2 > .column, .xxlarge-up-2 > .columns, .newslist .xxlarge-up-2 > .latestnews-item {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .xxlarge-up-3 {
    flex-wrap: wrap;
  }
  .xxlarge-up-3 > .column, .xxlarge-up-3 > .columns, .newslist .xxlarge-up-3 > .latestnews-item {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .xxlarge-up-4 {
    flex-wrap: wrap;
  }
  .xxlarge-up-4 > .column, .xxlarge-up-4 > .columns, .newslist .xxlarge-up-4 > .latestnews-item {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .xxlarge-up-5 {
    flex-wrap: wrap;
  }
  .xxlarge-up-5 > .column, .xxlarge-up-5 > .columns, .newslist .xxlarge-up-5 > .latestnews-item {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .xxlarge-up-6 {
    flex-wrap: wrap;
  }
  .xxlarge-up-6 > .column, .xxlarge-up-6 > .columns, .newslist .xxlarge-up-6 > .latestnews-item {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .xxlarge-up-7 {
    flex-wrap: wrap;
  }
  .xxlarge-up-7 > .column, .xxlarge-up-7 > .columns, .newslist .xxlarge-up-7 > .latestnews-item {
    flex: 0 0 14.2857142857%;
    max-width: 14.2857142857%;
  }
  .xxlarge-up-8 {
    flex-wrap: wrap;
  }
  .xxlarge-up-8 > .column, .xxlarge-up-8 > .columns, .newslist .xxlarge-up-8 > .latestnews-item {
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
}
@media screen and (min-width: 80em) and (min-width: 80em) {
  .xxlarge-expand {
    flex: 1 1 0px;
  }
}
.row.xxlarge-unstack > .column, .row.xxlarge-unstack > .columns, .newslist .row.xxlarge-unstack > .latestnews-item {
  flex: 0 0 100%;
}
@media screen and (min-width: 80em) {
  .row.xxlarge-unstack > .column, .row.xxlarge-unstack > .columns, .newslist .row.xxlarge-unstack > .latestnews-item {
    flex: 1 1 0px;
  }
  .xxlarge-collapse > .column, .xxlarge-collapse > .columns, .newslist .xxlarge-collapse > .latestnews-item {
    padding-right: 0;
    padding-left: 0;
  }
  .xxlarge-uncollapse > .column, .xxlarge-uncollapse > .columns, .newslist .xxlarge-uncollapse > .latestnews-item {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}

@media screen and (min-width: 80em) {
  .xxlarge-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .xxlarge-offset-0 {
    margin-left: 0%;
  }
  .xxlarge-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .xxlarge-offset-1 {
    margin-left: 8.3333333333%;
  }
  .xxlarge-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .xxlarge-offset-2 {
    margin-left: 16.6666666667%;
  }
  .xxlarge-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .xxlarge-offset-3 {
    margin-left: 25%;
  }
  .xxlarge-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .xxlarge-offset-4 {
    margin-left: 33.3333333333%;
  }
  .xxlarge-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .xxlarge-offset-5 {
    margin-left: 41.6666666667%;
  }
  .xxlarge-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .xxlarge-offset-6 {
    margin-left: 50%;
  }
  .xxlarge-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .xxlarge-offset-7 {
    margin-left: 58.3333333333%;
  }
  .xxlarge-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .xxlarge-offset-8 {
    margin-left: 66.6666666667%;
  }
  .xxlarge-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .xxlarge-offset-9 {
    margin-left: 75%;
  }
  .xxlarge-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .xxlarge-offset-10 {
    margin-left: 83.3333333333%;
  }
  .xxlarge-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .xxlarge-offset-11 {
    margin-left: 91.6666666667%;
  }
  .xxlarge-up-1 {
    flex-wrap: wrap;
  }
  .xxlarge-up-1 > .column, .xxlarge-up-1 > .columns, .newslist .xxlarge-up-1 > .latestnews-item {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .xxlarge-up-2 {
    flex-wrap: wrap;
  }
  .xxlarge-up-2 > .column, .xxlarge-up-2 > .columns, .newslist .xxlarge-up-2 > .latestnews-item {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .xxlarge-up-3 {
    flex-wrap: wrap;
  }
  .xxlarge-up-3 > .column, .xxlarge-up-3 > .columns, .newslist .xxlarge-up-3 > .latestnews-item {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .xxlarge-up-4 {
    flex-wrap: wrap;
  }
  .xxlarge-up-4 > .column, .xxlarge-up-4 > .columns, .newslist .xxlarge-up-4 > .latestnews-item {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .xxlarge-up-5 {
    flex-wrap: wrap;
  }
  .xxlarge-up-5 > .column, .xxlarge-up-5 > .columns, .newslist .xxlarge-up-5 > .latestnews-item {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .xxlarge-up-6 {
    flex-wrap: wrap;
  }
  .xxlarge-up-6 > .column, .xxlarge-up-6 > .columns, .newslist .xxlarge-up-6 > .latestnews-item {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .xxlarge-up-7 {
    flex-wrap: wrap;
  }
  .xxlarge-up-7 > .column, .xxlarge-up-7 > .columns, .newslist .xxlarge-up-7 > .latestnews-item {
    flex: 0 0 14.2857142857%;
    max-width: 14.2857142857%;
  }
  .xxlarge-up-8 {
    flex-wrap: wrap;
  }
  .xxlarge-up-8 > .column, .xxlarge-up-8 > .columns, .newslist .xxlarge-up-8 > .latestnews-item {
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
}
@media screen and (min-width: 80em) and (min-width: 80em) {
  .xxlarge-expand {
    flex: 1 1 0px;
  }
}
.row.xxlarge-unstack > .column, .row.xxlarge-unstack > .columns, .newslist .row.xxlarge-unstack > .latestnews-item {
  flex: 0 0 100%;
}
@media screen and (min-width: 80em) {
  .row.xxlarge-unstack > .column, .row.xxlarge-unstack > .columns, .newslist .row.xxlarge-unstack > .latestnews-item {
    flex: 1 1 0px;
  }
  .xxlarge-collapse > .column, .xxlarge-collapse > .columns, .newslist .xxlarge-collapse > .latestnews-item {
    padding-right: 0;
    padding-left: 0;
  }
  .xxlarge-uncollapse > .column, .xxlarge-uncollapse > .columns, .newslist .xxlarge-uncollapse > .latestnews-item {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}

.shrink {
  flex: 0 0 auto;
  max-width: 100%;
}

.column-block {
  margin-bottom: 1.25rem;
}
.column-block > :last-child {
  margin-bottom: 0;
}
@media print, screen and (min-width: 36em) {
  .column-block {
    margin-bottom: 1.875rem;
  }
  .column-block > :last-child {
    margin-bottom: 0;
  }
}

/*--------------------------------------------------------------
4.0 Components
--------------------------------------------------------------*/
/* Main
================================================== */
main {
  background-color: #fff3c6;
}

#new main {
  height: auto !important;
}

@media (max-width: 767px) {
  #newsletter main {
    height: auto !important;
  }
}

@media (max-width: 767px) {
  #enquire-to-exhibit main {
    height: auto !important;
  }
}

.label-warning {
  display: none;
}

@media (min-width: 576px) {
  .accounting-events h1 div {
    font-size: 8.5rem;
  }
}

.words {
  line-height: 0.8;
  opacity: 1;
  animation: replacement 2.5s infinite ease-in-out;
}

@keyframes replacement {
  0%, 100% {
    opacity: 0;
  }
  25%, 75% {
    opacity: 1;
  }
}
.custom-shape-divider-bottom-1696251135 {
  position: relative;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
}

.custom-shape-divider-bottom-1696251135 svg {
  position: relative;
  display: block;
  width: calc(165% + 1.3px);
}
@media (min-width: 576px) {
  .custom-shape-divider-bottom-1696251135 svg {
    height: 143px;
  }
}

.custom-shape-divider-bottom-1696251135 .shape-fill {
  fill: #FFFFFF;
}

/* Components
================================================== */
/* Social
================================================== */
.social ul {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  list-style: none;
}
.social ul li {
  width: auto;
  margin-bottom: 0;
}
.social ul li svg {
  width: 1.4em;
  height: 1.4em;
}
.social ul li svg * {
  fill: #ffd642;
}
.social ul li svg:hover * {
  fill: #ed2e69;
  transition: 400ms ease-in-out;
}

/* Header
================================================== */
header {
  background-image: url("images/yellow-waves-background.png") !important;
  background-size: cover;
  background-repeat: no-repeat;
  /*
      .row {
          align-items: center;
          text-align: center;
          padding: 2rem;
      } */
}
header .social {
  margin: 0 2rem 0 0;
}

.header .button {
  min-width: 220px;
  margin: 2rem 0.5rem 0.2rem 0.5rem;
}
@media (min-width: 576px) {
  .header .button {
    margin: 2rem 0.5rem;
  }
}
.header__left {
  display: none;
}
@media (min-width: 768px) {
  .header__left {
    display: block;
    text-align: left;
  }
}
.header__center {
  text-align: center;
}
.header__center img {
  width: 500px;
  margin: 3rem 0;
  padding: 0 1.5rem;
}
@media (min-width: 768px) {
  .header__center img {
    padding: 0;
  }
}
@media (min-width: 768px) {
  .header__right {
    text-align: right;
  }
}
.header__right p,
.header__right a {
  margin: 0;
}

/* Navigation
================================================== */
.nav {
  display: none;
  width: auto;
  margin: 0;
  padding: 0;
  background: #ffffff;
}
@media (min-width: 768px) {
  .nav {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
  }
}
.nav li {
  position: relative;
  margin-bottom: 0;
  text-align: center;
  list-style: none;
}
@media (max-width: 767px) {
  .nav li {
    width: auto;
    text-align: center;
  }
}
@media (min-width: 960px) {
  .nav li:last-of-type .mod-menu__sub {
    right: 0;
  }
}
@media (min-width: 960px) {
  .nav li:last-of-type .mod-menu__sub li {
    text-align: right;
  }
}
.nav a {
  display: block;
  padding: 1rem 1.6rem;
  color: #0f0f0f;
  font-size: 1rem;
  text-decoration: none;
  line-height: 1.2;
}
.nav a:hover,
.nav .current > a,
.nav .active > a {
  color: #ed2e69;
}
.nav ul {
  display: none;
}
.nav .parent > a {
  position: relative;
}
.nav .parent > a:after {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  right: 10%;
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 7px 5px 0 5px;
  border-color: #000000 transparent transparent transparent;
}
.nav .parent:hover ul {
  display: flex;
}
.nav .parent ul {
  display: none;
  flex-flow: column nowrap;
  list-style: none;
  width: 100%;
  margin: 0;
  padding: 0;
}
@media (min-width: 768px) {
  .nav .parent ul {
    position: absolute;
    top: 100%;
    align-items: flex-start;
  }
}
.nav .parent ul li {
  width: 100%;
}
.nav-active .nav {
  display: flex;
  width: 100%;
  height: 100vh;
  position: absolute;
  margin-left: 0;
  left: 0;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  padding-top: 8em;
}
@media (max-width: 479px) {
  .nav-active .nav {
    padding-top: 5em;
  }
}

/* Hamburger / Menu
================================================== */
.nav-button {
  display: none;
  flex-direction: row;
  align-items: center;
  position: relative;
  z-index: 1;
  margin: 0 0 0 auto;
  padding: 1rem;
  background: transparent;
  outline: 0;
  cursor: pointer;
}
.nav-button p {
  margin: 0;
  padding: 0 0.75rem 0 0;
  color: #0f0f0f;
}
.nav-button span,
.nav-button span:before,
.nav-button span:after {
  content: "";
  display: block;
  position: absolute;
  right: 0;
  width: 2em;
  height: 4px;
  background: #0f0f0f;
  border-radius: 1px;
  -webkit-transition: 400ms ease-in-out;
  -moz-transition: 400ms ease-in-out;
  -ms-transition: 400ms ease-in-out;
  -o-transition: 400ms ease-in-out;
  transition: 400ms ease-in-out;
}
.nav-button span {
  position: relative;
}
.nav-button span:before {
  top: -10px;
}
.nav-button span:after {
  top: auto;
  bottom: -10px;
}
.nav-active .nav-button span {
  background: transparent;
}
.nav-active .nav-button span:before, .nav-active .nav-button span:after {
  top: 0;
  background: #000;
}
.nav-active .nav-button span:before {
  transform: rotate(45deg);
}
.nav-active .nav-button span:after {
  transform: rotate(-45deg);
}
@media (min-width: 960px) {
  .nav-button {
    display: none;
  }
}

button.nav-button {
  background: transparent;
}

/* Banner / Slider
================================================== */
#banner {
  background: url("images/yellow-waves-background.png") !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  margin: 0;
  padding: 5rem 0 0 0;
}
#banner p {
  font-size: 1.6rem;
  font-weight: bold;
}

.custom-shape-divider-banner {
  position: relative;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
}

.custom-shape-divider-banner svg {
  position: relative;
  display: block;
  width: calc(165% + 1.3px);
}
@media (min-width: 576px) {
  .custom-shape-divider-banner svg {
    height: 143px;
  }
}

.custom-shape-divider-banner .shape-fill-banner {
  fill: #fff3c6;
}

/* Page Specific Styling
================================================== */
.landing-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #ffd642;
  background-image: url("images/yellow-waves-background.png") !important;
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;
}
.landing-page body {
  overflow-y: hidden;
}

.ae2023 {
  padding-top: 10rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #ffd642;
  background-image: url("images/yellow-waves-background.png") !important;
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;
}
@media (min-width: 768px) {
  .ae2023 {
    padding: 0;
  }
}
.ae2023 .row {
  background-image: url("images/yellow-waves-background.png");
  background-size: cover;
  margin-top: 20rem;
}
@media (min-width: 480px) {
  .ae2023 .row {
    margin-top: 15rem;
  }
}
@media (min-width: 576px) {
  .ae2023 .row {
    margin-top: 0;
    background-image: none;
  }
}
.ae2023 body {
  overflow-y: hidden;
}
.ae2023 .com-baforms-wrapper .ba-form-page .ba-form-field-item {
  margin: 0;
}

/* Sections 
================================================== */
section {
  position: relative;
  overflow: hidden;
  padding: 5rem 0;
}

.section--sponsors .custom-shape-divider-top-1696249977 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
}
.section--sponsors .custom-shape-divider-top-1696249977 svg {
  position: relative;
  display: block;
  width: calc(165% + 1.3px);
  height: 143px;
}
.section--sponsors .custom-shape-divider-top-1696249977 .shape-fill {
  fill: #FFFFFF;
}

/* News
================================================== */
.newslist .latestnews-items {
  flex-flow: column !important;
  align-items: normal !important;
  -webkit-justify-content: normal !important;
  -ms-flex-pack: normal !important;
  justify-content: normal !important;
  overflow: visible !important;
}
@media (min-width: 576px) {
  .newslist .latestnews-items {
    flex-flow: row wrap !important;
  }
}
.newslist .latestnews-item {
  flex: 1;
  padding: 0 1rem 2rem !important;
}
@media (min-width: 576px) {
  .newslist .latestnews-item {
    flex: 0 0 50%;
    max-width: 50%;
  }
}
@media (min-width: 960px) {
  .newslist .latestnews-item {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
}
.newslist .latestnews-item > a {
  display: block;
  height: 100%;
}
.newslist .latestnews-item > a:hover .detail_category,
.newslist .latestnews-item > a:hover .detail_tags {
  opacity: 1;
}
.newslist .latestnews-item > a:hover .link a {
  background-color: #ffd642;
}
.newslist .latestnews-item > a:hover .link a span {
  color: #ffffff;
}
.newsSection .newslist .latestnews-item {
  border: 0;
}
.newslist .latestnews-item.active {
  opacity: 1 !important;
}
.newslist .newshead {
  float: none !important;
  margin: 0 !important;
}
.newslist .innerpicture {
  width: 100%;
}
.newslist .innerpicture:after {
  content: "";
  display: block;
  padding-bottom: 60%;
}
.newslist .innerpicture img {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.newslist .news {
  position: relative !important;
  height: 100% !important;
  margin: 0 !important;
  padding: 0rem !important;
  background-color: transparent !important;
  border: none !important;
}
.newslist .newstitle {
  font-size: 1.6rem;
  font-family: "degular-regular", sans-serif !important;
  font-weight: normal !important;
  line-height: 1.2 !important;
  margin: 0 0 0.5em 0 !important;
}
.newslist .newstitle a {
  color: #ffd642;
}
.newslist .newstitle a:hover {
  color: #ed2e69;
}
.newslist .newsinfo {
  padding: 1.5rem 1rem 4rem !important;
}
.newslist .newsinfo * {
  color: #ffd642;
}
.newslist .newsintro {
  line-height: 1.2 !important;
}
.newslist .detail_category,
.newslist .detail_tags {
  opacity: 1;
  display: inline-flex;
  padding: 0.4rem 0.8rem;
  border-radius: 50px;
  background: #edf0ef;
  font-family: "degular-regular", sans-serif;
  font-weight: 700;
  font-size: 0.8rem;
  color: #ffd642;
  transition: 400ms ease-in-out;
}
@media (min-width: 576px) {
  .newslist .detail_category,
  .newslist .detail_tags {
    opacity: 0.25;
  }
}
.newslist .detail_date {
  display: flex;
  align-items: center;
  margin: 0 0 0.25rem 0;
  text-transform: uppercase;
  font-family: "degular-regular", sans-serif;
  font-size: 0.6rem;
  font-weight: 400;
}
.newslist .delimiter {
  display: none;
}
.newslist .link {
  width: 100% !important;
  margin-top: 1.5em !important;
}
.newslist .link a {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 0.5rem 0;
  background-color: #ffd642;
  color: #ffd642;
  text-align: center;
  font-family: "degular-regular", sans-serif;
  text-transform: uppercase;
  font-weight: 400;
}
.newslist .link span {
  transition: 400ms ease-in-out;
}
@media (min-width: 576px) {
  .newslist--full-width .latestnews-item {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
}
.newslist--full-details .latestnews-item .newsinfo {
  padding: 1.5rem 1rem !important;
}
.newslist--full-details .latestnews-item ul,
.newslist--full-details .latestnews-item p {
  margin-bottom: 1rem !important;
}

/* News Page
  ================================================== */
.news-item-article .main-content {
  flex-direction: column;
  padding: 0;
}
.news-item-article .published {
  margin: 0;
  font-family: "degular-regular", sans-serif;
  text-transform: uppercase;
  font-weight: 400;
}
.news-item-article .com-content-image,
.news-item-article .item-page {
  max-width: 960px;
  width: 100%;
  margin: auto;
}
.news-item-article .com-content-image {
  margin-bottom: 0;
}
.news-item-article .com-content-image figure {
  position: relative;
  margin: 0;
  background-color: #ffffff;
}
.news-item-article .com-content-image img {
  display: block;
  width: 100%;
  height: 100%;
  max-height: 600px;
  object-fit: cover;
}
.news-item-article .item-page {
  padding: 3em;
  background-color: #ffffff;
}
.news-item-article .item-page * {
  color: #0f0f0f;
}
.news-item-article .article-info.muted {
  display: block;
  color: #ffd642;
  margin-bottom: 2em;
}

.sharethis-inline-share-buttons {
  margin-top: 4rem;
  margin-bottom: 2rem;
}

.article-info.muted {
  display: none;
}

/* Footer
================================================== */
footer {
  width: 100%;
  background-color: #0f0f0f;
  color: #ffd642;
}
footer h4 {
  margin: 0;
}

.footer__divider {
  position: relative;
  top: -1px;
}
.footer__wrap {
  width: 100%;
  margin-top: 1rem;
  /*	h3 {
  		margin: 0 0 2rem;
  		font-weight: normal;
  		font-size: 1.2rem;
  		color: $secondary-colour;
  		text-transform: uppercase;
  		font-weight: bold;
  		font-family: $primary-font;
  	}
  	h4 {
  		margin-bottom: 0.5rem;
  		font-size: 1.2rem;
  		font-weight: normal;
  		font-family: $primary-font;
  	} */
}
.footer__wrap .row {
  align-items: center;
}
@media (max-width: 575px) {
  .footer__wrap > div {
    text-align: center;
  }
}
@media (max-width: 1279px) {
  .footer__wrap > div {
    margin-bottom: 3rem;
  }
}
.footer__wrap p {
  font-family: "degular-regular", sans-serif;
}
.footer__left {
  text-align: center;
}
.footer__left h3 {
  margin-bottom: 1rem;
}
.footer__left--sponsor {
  font-size: 0.7rem;
}
.footer__left--sponsor img {
  max-width: 130px;
}
.footer__logo img {
  max-width: 220px;
  height: auto;
  margin: 0 auto 2rem;
}
@media (min-width: 1280px) {
  .footer__logo img {
    margin: 0 0 2rem 0;
  }
}
.footer__sponsors {
  display: flex;
  flex-direction: column;
}
.footer__sponsors img {
  margin: 0.5rem 0;
}
.footer__social {
  align-self: center;
}
@media (min-width: 960px) {
  .footer__social {
    margin-top: 1rem;
  }
}
.footer__social ul {
  display: flex;
  flex-flow: row nowrap;
  padding: 0;
}
@media (max-width: 1279px) {
  .footer__social ul {
    justify-content: center;
    margin: auto;
  }
}
.footer__social li {
  list-style-type: none;
}
@media (max-width: 1279px) {
  .footer__social li {
    padding: 0.5rem 1rem;
  }
}
.footer__social a * {
  fill: #ffd642;
}
.footer__social a:hover * {
  fill: #ed2e69;
  transition: 400ms ease-in-out;
}
.footer__dates, .footer__hours, .footer__hocation {
  text-align: center;
}
.footer__dates p, .footer__hours p, .footer__hocation p {
  margin-bottom: 1.5rem;
}
.footer__dates {
  order: 1;
}
@media (min-width: 960px) {
  .footer__dates {
    order: 2;
  }
}
.footer__hours {
  order: 2;
}
@media (min-width: 960px) {
  .footer__hours {
    order: 3;
  }
}
.footer__location {
  order: 3;
}
@media (min-width: 576px) {
  .footer__location {
    order: 4;
  }
}
.footer__bottom {
  width: 100%;
  margin: 6rem 0 0;
  padding: 1em 3em;
  background-color: #0f0f0f;
}
.footer__bottom * {
  text-transform: uppercase;
}
.footer__bottom .row {
  align-items: center;
}
.footer__bottom .footer__links,
.footer__bottom .footer__signature {
  font-size: 0.7em;
}
@media (max-width: 575px) {
  .footer__bottom .footer__links,
  .footer__bottom .footer__signature {
    padding: 0.5rem 0;
    text-align: center;
  }
}
@media (min-width: 576px) {
  .footer__bottom .footer__links {
    justify-content: flex-start;
  }
}
.footer__bottom .footer__links a {
  margin-right: 1rem;
}
.footer__bottom .footer__links a:hover {
  color: #ed2e69;
}
.footer__bottom .footer__signature {
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (min-width: 576px) {
  .footer__bottom .footer__signature {
    justify-content: flex-end;
  }
}
.footer__bottom .footer__signature img {
  margin-left: 1rem;
  width: 10em;
  -webkit-transition: 400ms ease-in-out;
  -moz-transition: 400ms ease-in-out;
  -ms-transition: 400ms ease-in-out;
  -o-transition: 400ms ease-in-out;
  transition: 400ms ease-in-out;
}

.custom-shape-divider-footer {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
}

.custom-shape-divider-footer svg {
  position: relative;
  display: block;
  width: calc(165% + 1.3px);
}
@media (min-width: 576px) {
  .custom-shape-divider-footer svg {
    height: 143px;
  }
}

.custom-shape-divider-footer .shape-fill {
  fill: #FFFFFF;
}