.relative {
  position: relative;
}
.absolute {
  position: absolute;
}
.static {
  position: static;
}
.fixed {
  position: fixed;
}
.sticky {
  position: sticky;
}
